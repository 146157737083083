<template>
    <div class="sharp">
        <el-card>
            <div slot="header" class="clearfix">
                <span>夏普比率</span>
                <date-range style="float: right" v-on:change="getData"></date-range>
            </div>
            <h-chart ref="chart" id="chart" :options="options"></h-chart>
            <div class="blackboard">
                <p class="star-level"><span>指标星级：</span>
                    <el-rate v-model="starLevel" disabled></el-rate>
                </p>
                <p>指标释义：计算过去每承担一份风险获得的收益。</p>
                <p>
                    如何使用：指标描述了该账户收益是否稳健和可持续性。一般来说，统计周期超过一年，夏普比率大于1，那么恭喜你，投资水平已属于优秀行列；如夏普比率超过2，则非常优秀了，国内外市场优秀的基金经理也不过如此。</p>
            </div>
        </el-card>
    </div>
</template>

<script>
    import HChart from '@/components/HChart.vue'
    import DateRange from '@/components/DateRange.vue'
    import {Loading} from "element-ui";
    import {apiAnalysisData} from "../../api/analysis";

    export default {
        name: "sharp",
        data() {
            return {
                starLevel: 4,
                options: {
                    credits: {
                        enabled: false
                    },
                    chart: {
                        backgroundColor: null,
                        plotBackgroundColor: null
                    },
                    title: false,
                    colors: ['#EB3E41'],
                    plotOptions: {
                        series: {
                            marker: {
                                enabled: false
                            },
                        }
                    },
                    xAxis: {
                        // tickInterval: 20,
                        categories: []
                    },
                    yAxis: {
                        title: false,
                    },
                    series: [],
                }
            };
        },
        components: {
            HChart,
            DateRange
        },
        methods: {
          async getData () {
            if (this.$store.state.curAccountId.length === 0) {
              return false;
            }
            let loadingInstance = Loading.service({target: '.h-chart'});
            const params = {
              a_id: this.$store.state.curAccountId.join(','),
              type: this.$store.state.analysisType,
              b_date: this.$store.state.dateRange.b_date,
        e_date: this.$store.state.dateRange.e_date
            }
            let res = await apiAnalysisData('sharpe', params)
            if (res.data.code === 0 && res.data.data) {
              const resData = JSON.parse(JSON.stringify(res.data.data))
              let categories = []
              let f1s = []
              Array.isArray(resData) && resData.map(item => {
                if (item.date) {
                  categories.push(item.date)
                }
                if (item.f1 || item.f1 === 0) {
                  f1s.push(item.f1)
                }
              })
              this.options.xAxis.categories = categories;
              this.options.series = [{
                name: '夏普比率',
                data: f1s
              }];
              this.$refs.chart.redraw();
              loadingInstance.close();
            }
          }
        },
        mounted() {
            this.getData();
        }
    }
</script>

<style lang="scss">
    .sharp {
        .el-card {
            .el-card__header {
                background: #1777FF;
                color: #ffffff;
            }

            .chart {
                margin-bottom: 20px;
                background-image: url("../../assets/watermark.png");
                background-repeat: no-repeat;
                background-size: 269px 150px;
                background-position: center;
            }
        }
    }
</style>
